body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-spin-dot-item {
  background-color: #6d2077 !important;
}

.ant-spin-text {
  background-color: #6d2077 !important;
}

@font-face {
  font-family: 'Nexa Bold';
  src: local('GoldmanBold'), url('../src/fonts/NexaBold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Nexa XBold';
  src: local('Nexa--XBold'),
    url('../src/fonts/Nexa-XBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa Regular';
  src: local('GoldmanBold'),
    url('../src/fonts/NexaRegular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Nexa Book';
  src: local('Nexa--Book'), url('../src/fonts/Nexa-Book.ttf') format('truetype');
  font-weight: normal;
}