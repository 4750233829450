.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.leaflet-container {
  background-color: rgba(215, 23, 23, 0);
}
.ant-table-row-level-0:hover {
  cursor: pointer;
}
.ant-popover-inner-content {
  padding: 5px 5px;
}
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  color: black !important;
}
.ant-menu-item::after {
  border-right: none !important;
}
body {
  height: 100%;
}
.blur {
  background: gray;
  height: 100%;
  width: 100%;
  position: fixed;
  opacity: 0.4;
  z-index: 2;
}
.ant-layout {
  background-color: #f7f6fd;
}
.slider {
  background: white;
  height: 100vh;
  z-index: 100;
  overflow: scroll;
}
.content {
  display: flex;
  background-color: #f7f6fd;
  flex-direction: column;
  min-height: 100vh;
}
.login {
  justify-content: center !important;
  display: flex;
  background-color: white;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  @media only screen and (max-width: 432px) {
    align-items: normal;
  }
}
.tab {
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  flex-direction: column;
}
.ant-table-wrapper {
  padding-right: 0 !important;
}
.ant-pagination {
  padding-right: 10px !important;
}
.ant-pagination-item-link {
  border: none !important;
  font-size: 16px !important;
  color: gray !important;
}
.ant-pagination-item {
  display: none !important;
}
.ant-input:focus,
.ant-select-selector:focus {
  border-color: #6d1f7c !important;
}
.ant-input:hover,
.PhoneInputInput:hover,
.ant-select-selector:hover {
  border-color: #6d1f7c !important;
}
.ant-select-selector {
  border-color: #d9d9d9 !important;
  align-items: center !important;
}
.arrow {
  width: 24px;
  height: 24px;
  margin: 38px 0px 0px 10px;
}
.select {
  margin-top: 20px;
  border: none;
  outline: none;
  color: #4b506d;
  margin-right: 40px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
img {
  cursor: pointer;
}
.ant-table-thead > tr > th {
  border-bottom: 1px #dfe0eb !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #dfe0eb !important;
  height: 48px;
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.ant-table-cell a {
  font-family: 'Roboto', 'sans-serif';
}
.ant-pagination-item-ellipsis {
  display: none;
}
.newStyle .ant-modal-content,
.importcsv-modal .ant-modal-content,
.dltCategoryModal .ant-modal-content,
.manageCategoriesModal .ant-modal-content {
  padding: 0;
  width: 499px;
  height: auto;
  color: #342a53;
  border-radius: 6px;
}
.ant-modal .ant-modal-footer {
  margin: 0;
  padding: 20px 30px;
  justify-items: center;
}
.ant-modal .ant-modal-close {
  top: 24px;
  inset-inline-end: 25px;
}
.ant-modal .ant-modal-header,
.ant-modal .ant-modal-body {
  margin: 0;
  padding: 20px 30px;
  border-bottom: 1px solid #f0f0f0;
}
.questionsTable.ant-table-wrapper .ant-table {
  border-radius: 8px 8px 8px 8px !important;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #dde1e6;
  border-radius: 8px;
}
.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border-bottom: none !important;
}
.ant-table-wrapper .ant-table-tbody > tr:last-child > td:first-child {
  border-radius: 0px 0px 0px 8px;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-inline-end: none;
}
.questionInput .ant-input,
.catInput .ant-input {
  padding: 10px 14px;
  color: #342a53;
}
.questionInput .ant-input::placeholder,
.catInput .ant-input::placeholder {
  color: #b9c1c7;
}
.questionInput .ant-input,
.ant-input::placeholder,
.catInput .ant-input,
.ant-input::placeholder {
  line-height: 1.45;
  font-family: 'Poppins';
}
.ant-input-textarea-show-count .ant-input-data-count {
  left: 0;
  bottom: -25px;
}
.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 8px;
}
.searchInput .ant-input::placeholder {
  color: #79818e;
  font-family: 'Poppins';
  font-size: 14px;
}
.ant-input-affix-wrapper.searchInput {
  height: 44px;
  max-width: 206px;
  padding: 10px 14px;
}
.ant-input-affix-wrapper.searchInput:focus-within {
  max-width: 500px;
}
.ant-input-affix-wrapper,
.categorySelect .ant-select-selector {
  border-color: #b9c1c7 !important;
}
.ant-tag-borderless {
  background: #d8e0e5;
  border: none;
}
.ant-tag {
  font-family: 'Poppins';
  line-height: 143%;
  font-size: 14px;
  font-weight: 400;
  color: #342a53;
  padding: 4px 11px;
  border-radius: 6px;
}
.ant-tag .ant-tag-close-icon {
  vertical-align: top;
  margin-inline-start: 10px;
}
.ant-table-wrapper .ant-table-column-sorters::after {
  position: unset;
}
.ant-table-wrapper .ant-table-column-sorter-up.active,
.ant-table-wrapper .ant-table-column-sorter-down.active {
  color: #6d1f7c !important;
}

.questionsTable .ant-pagination-item,
.categoryTable .ant-pagination-item {
  font-family: 'Poppins';
  display: inline-block !important;
}
.questionsTable .ant-pagination-item-active,
.questionsTable .ant-pagination-item-active:hover,
.categoryTable .ant-pagination-item-active,
.categoryTable .ant-pagination-item-active:hover {
  border-color: #6d1f7c !important;
}
.questionsTable .ant-pagination-item-active a,
.questionsTable .ant-pagination-item-active:hover a,
.questionsTable .ant-pagination-next a,
.questionsTable .ant-pagination-prev:hover a,
.questionsTable .ant-pagination-prev a,
.questionsTable .ant-pagination-next:hover a,
.categoryTable .ant-pagination-item-active a,
.categoryTable .ant-pagination-item-active:hover a,
.categoryTable .ant-pagination-next a,
.categoryTable .ant-pagination-next:hover a,
.categoryTable .ant-pagination-prev a,
.categoryTable .ant-pagination-prev:hover a,
.questionsTable
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.questionsTable
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.categoryTable
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.categoryTable
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #6d1f7c !important;
}
.questionsTable .ant-pagination-prev.ant-pagination-disabled a,
.questionsTable .ant-pagination-next.ant-pagination-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
}
.questionsTable .ant-pagination-total-text,
.categoryTable .ant-pagination-total-text {
  left: 0;
  top: -37px;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #121619;
  line-height: 140%;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-family: 'Nexa Regular';
}
.ant-table-wrapper.questionsTable {
  margin: 47px 0 0;
}
.questionsTable .ant-table-row-level-0:hover,
.categoryTable .ant-table-row-level-0:hover {
  cursor: unset;
}
.questionsTable.ant-table-wrapper .ant-table-cell p,
.categoryTable.ant-table-wrapper .ant-table-cell p {
  margin: 0;
}
.questionsTable .ant-table-content > table > tbody > tr > td:last-child,
.categoryTable .ant-table-content > table > tbody > tr > td:last-child {
  padding: 8px 14px;
}
.questionsTable
  .ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell,
.categoryTable
  .ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell,
.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell-row-hover,
.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: white !important;
}
.questionsTable .ant-checkbox-checked .ant-checkbox-inner,
.categoryTable .ant-checkbox-checked .ant-checkbox-inner {
  background: #0f62fe !important;
  border-color: #0f62fe !important;
}
.questionsTable .ant-checkbox-inner,
.categoryTable .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-radius: 0px;
  border-color: #121619;
}
.questionsTable .ant-checkbox-inner::after,
.categoryTable .ant-checkbox-inner::after {
  background-color: transparent;
}
.questionsTable.ant-table-wrapper .ant-table-selection-column,
.categoryTable.ant-table-wrapper .ant-table-selection-column {
  width: 3% !important;
  padding-inline-end: 12px !important;
  padding-inline-start: 12px !important;
}
.dltCategoryModal .ant-modal-content .ant-modal-body {
  padding: 13px 30px;
}
.manageCategoriesModal .ant-modal-content,
.ant-modal.manageCategoriesModal {
  width: 100% !important;
  max-width: 762px !important;
  @media screen and (max-width: 830px) {
    max-width: 650px !important;
  }
  @media screen and (max-width: 680px) {
    max-width: 450px !important;
  }
}
.importcsv-modal .ant-modal-content,
.ant-modal.importcsv-modal {
  width: 100% !important;
  max-width: 530px !important;
  @media screen and (max-width: 830px) {
    max-width: 450px !important;
  }
  @media screen and (max-width: 680px) {
    max-width: 350px !important;
  }
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 20px 0 0;
}
.manageCategoriesModal.ant-modal .ant-modal-body {
  padding: 30px 30px 10px;
}
.ant-table-wrapper.categoryTable {
  margin: 36px 0 0;
}
.categoryTable .ant-table-tbody > tr > td {
  height: 47px;
}
.manageCategoriesModal .ant-modal-body > :first-child {
  display: flex !important;
  justify-content: flex-end !important;
}
.category-row .ant-form-item-explain {
  position: absolute !important;
  top: 93% !important;
  font-size: 10px !important;
}
.ant-table-wrapper .ant-table-thead > tr > td {
  border-bottom: none;
  border-inline-end: none !important;
}
.ant-form-item-control-input-content .ant-input:hover {
  border-color: #b9c1c7 !important;
}
.category-row .ant-input,
.category-row .ant-input:hover {
  padding: 5px 9px !important;
  font-family: 'Poppins' !important;
  text-overflow: ellipsis !important;
  border-color: #b9c1c7 !important;
}
.category-row .ant-input:focus-within {
  box-shadow: none;
}
.category-row span div a {
  font-family: 'Nexa Bold' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  letter-spacing: 0.5px !important;
}

@media screen and (max-width: 680px) {
  .category-row span div {
    display: flex !important;
    flex-direction: column !important;
  }
  .category-row span div a {
    font-size: 12px !important;
  }
  .category-row span div a:first-child {
    margin-bottom: 11px !important;
  }
}
.ant-btn-primary {
  box-shadow: none !important;
}
.ant-progress .ant-progress-text {
  display: none !important;
}
.ant-progress.ant-progress-show-info .ant-progress-outer,
.ant-progress.ant-progress-line {
  margin: 0 !important;
  margin-inline-end: 0 !important;
  padding-inline-end: 0 !important;
}
.ant-progress .ant-progress-inner {
  vertical-align: unset !important;
  background-color: #cbd0d3 !important;
}
.ant-progress .ant-progress-inner .ant-progress-bg {
  height: 12.4px !important;
  background-color: #22ab7d !important;
}
.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}
.ant-select-dropdown .ant-select-item-option-selected {
  background-color: #f7f6fd !important;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #edf1f3 !important;
}
.ant-table-wrapper td.ant-table-column-sort {
  background-color: white !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within {
  box-shadow: none !important;
}
.questionsTable .ant-table-expanded-row-fixed {
  display: none !important;
}
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  @media screen and (max-width: 1350px) {
    margin: 0 auto;
  }
}
.categoryTable .ant-pagination li {
  display: none !important;
}
.categoryTable .ant-pagination li:first-child {
  display: flex !important;
}
.ant-btn-primary:disabled {
  border: none !important;
  color: #b9c1c7 !important;
  background-color: #edf1f3 !important;
}
.ant-btn-primary {
  color: white !important;
  font-family: 'Nexa Bold' !important;
  background-color: #6d1f7c !important;
}
.cancelBtn.ant-btn-default,
.cancelBtn.ant-btn-default:hover {
  border-color: #6d1f7c !important;
  color: #6d1f7c !important;
  font-family: 'Nexa Bold' !important;
}
.ant-message-info > .anticon svg {
  fill: #6d1f7c !important;
}
.loginBtn.ant-btn-default:hover {
  color: white !important;
  border-color: #6d1f7c !important;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 90vh;
}
